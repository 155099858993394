
/* SCSS Mixins*/

@mixin breakpoint($point) {
  @if $point == desktop {
     @media (max-width: 940px) { @content ; }
  }
   @else if $point == laptop {
     @media (max-width: 780px) { @content ; }
  }
   @else if $point == tablet {
     @media (max-width: 640px)  { @content ; }
  }
  @else if $point == mobile {
     @media (max-width: 320px)  { @content ; }
  }
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    overflow-x: hidden;
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }
  body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }
  a[href^="tel"]{
      color:inherit;
      text-decoration:none;
  }
  article, aside, dialog, figure, footer, header, hgroup, nav, section, div {
    display: block;
  }
  button, input, input, select, {


    outline: none;
    font-family: $font;
  }
  input[type="submit"], button {
    cursor: pointer;
    border: 0;
    outline: none;
  }
  li, a, input {outline: none}
  body {
    line-height: 1;
    font-size: 10px;
    font-family: $font;
    overflow: hidden;
  }
  ol,ul, li {
    list-style: none;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  table, th, td {
    vertical-align: middle;
  }

  blockquote before, blockquote after, q before, q after {
    content: '';
  }

  a img {
    border: none;
  }
  a {
    text-decoration: none;
  }
  .clearfix {
    &:after {
      clear: both;
      display: block;
      content: ''
    }
  }
  .reset {
    clear: both;
    display: block;
  }

  .error {
    color: #9a0000 !important;
  }



  @media print {
    body {
      font-size: 9px;
    }
  }


html{
  height: 100%;
}
body {
  line-height: 1;
  font-size: 10px;
  font-family: $font;
  background: #ECECEC;
  min-height: 100%;
  @include breakpoint(tablet) {
    font-size: 9px;
  }
}

.box-loading{
  width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #055eb3;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast{
  font-size: 14px;
}

.react-datepicker-wrapper, .react-datepicker__input-container{
  width: 100%;
}
