.page-header{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
	form{
    input{
        background: none;
        border: 0;
        padding: 0 0 0 30px;
        border-bottom: solid 1px #000;
        height: 30px;
        font-size: 14px;
        font-family: $font;
        font-weight: 300;
        color: #8B8D97;
        box-sizing: border-box;
        &.date{
          background:url(/img/icons/icon-calendar.png) no-repeat left;
          width: 130px;
          margin-left: 20px;
        }
        &.search{
          background:url(/img/icons/icon-search.png) no-repeat left;
          width: 220px;
          margin-left: 20px;
        }
    }
	}
	.last-wrap{
      display: flex;
      align-items: center;
  }
  .exportexcel{
    box-sizing: border-box;
    height: 40px;
    border-radius: 6px;
    padding: 0 10px;
    background: #f9f9f9;
    transition: all ease .2s;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    span{
      position: absolute;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-200%);
      transition: all ease .2s;
      margin-left: -10px;
    }
    img{
      height: 25px;
      transition: all ease .2s;

    }
    p{
      font-size: 12px;
      line-height: 40px;
      transition: all ease .4s;
      transform: translateY(0%);
      color: #999;
    }
    &:hover{
      background: #408c44;
      span{
        transform: translateY(0);
      }
      p{
        transform: translateY(200%);
        opacity: 1;
      }
    }
  }
	.btnvoltar{
		display: flex;
		align-items: center;
		cursor: pointer;
		img{
			margin-right: 10px;
		}
		p{
			font-size: 14px;
			color: #000;
		}
	}
}

.btn-filter-nfs{
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 12px 15px;
	border-radius: 6px;
	cursor: pointer;
	transition: .3s;
	img{
			margin-right: 10px;
	}
	.icon-filter{
        margin-right: 10px;
        span{
                display: block;
                width: 17px;
                height: 2px;
                background: #000;
                margin-bottom: 4px;
                position: relative;

                &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        top: -2px;
                        left: 2px;
                        border-radius: 50%;
                        width: 2.5px;
                        height: 2px;
                        border: solid 2px #000;
                        background: #ECECEC;
                        transition: all ease 0.2s;
                }
                &:nth-child(2n){
                        &:before{
                                left: 10px;
                                transition: all ease 0.4s;
                        }
                }
                &:last-child{
                        &:before{
                                transition: all ease 0.6s;
                        }
                }
        }
	}
	&:hover, &.active{
		background: #fff;
        .icon-filter{
            span{
                &:before{
                    left: 10px;
                }
                &:nth-child(2n){
                    &:before{
                            left: 2px;
                    }
                }
            }
        }
	}

}

.filter-box{
	padding: 20px;
	background: #fff;
	border-radius: 6px;
	border-left: solid 7px #4C5062;
	box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
	margin-bottom: 30px;
	position: relative;
	transition: all ease 0.3s;
	display: none;
	transform: translate(0, -20px);
	opacity: 0;
	z-index: 9;
	&.active{
		display: block;
		transform: translate(0, 0);
		opacity: 1;

	}
	&:before{
		content: '';
		position: absolute;
		top: -6px;
		right: 50px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 6px 6px 6px;
		border-color: transparent transparent #ffffff transparent;
	}
	form{
		display: flex;
		align-items: center;
		justify-content: space-between;
		label{
			display: block;
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 10px;
			text-align: left;
		}
		.boxurgente{

			height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			label{
				margin-bottom: 0;
				display: flex;
				align-items: center;
				margin-right: 20px;
				input{
					margin-right: 10px;
				}
			}
    }
    .boxstatus{

			height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			label{
				margin-bottom: 0;
				display: flex;
				align-items: center;
				margin-right: 10px;
				input{
					margin-right: 10px;
				}
			}
		}
		input, select{
			width: 240px;
			height: 50px;
			border: solid 1px #C2C2C2;
			box-sizing: border-box;
			border-radius: 6px;
			padding: 0 10px;
			font-size: 14px;
			font-weight: 300;
			transition: all ease 0.3s;
			&:focus{
					border: solid 1px $cor;
			}
			&.date{
				width: 180px;
				background: url(../img/icons/icon-calendar.png)no-repeat 90%;
			}

		}
		.react-datepicker__input-container input{
			width: 180px;
			background: url(../img/icons/icon-calendar.png)no-repeat 90%;
		}
		input[type="checkbox"]{
			width: 18px !important;
			height: 18px;
			appearance: none;
			padding: 0;
			border: solid 2px #929292;
			background-position: center;
			&:checked{
					background: url(../img/icons/icon-check.png)no-repeat center;
					border: solid 2px #292D41;
			}
		}
		.nfs{
			input{
					margin-right: 10px;
			}
			label{
					display: flex;
					align-items: center;
					font-size: 15px;
					&:last-child{
							margin-bottom: 0;
					}
			}
		}
		&.formoperacional{
      .row{
        margin-bottom: 20px;
        button{
          width: 100%;
        }
      }
      .item span {
        display: flex;
        justify-content: space-between;
        .react-datepicker-wrapper{
          width: 48%;
        }
      }
			input{
				width: 100%;
			}
		}
	}
	.btn-status{
		width: 50px;
		height: 50px;
		background-color: #8B8D97;
		background-image: url('../img/icons/icon-allstatus.png');
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 50%;
		cursor: pointer;
	}
	.btn{
		height: 50px;
	}
	@media (max-width: 950px){
		form{
			flex-wrap: wrap;
		}
	}
	@media (max-width: 720px){
		form{

			.nfs{
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding-bottom: 10px;
				border-bottom: solid 1px #ccc;
				margin-bottom: 10px;
				label{
					margin-bottom: 0;
				}
			}
			.item{
				width: 100%;
				margin-bottom: 10px;
				input[type="text"]{
					height: 40px;
					width: 100%;
				}
			}
			.btn{
				width: 100%;
			}
		}
	}
}

.btn{
	border-radius: 6px;
	background: $verde;
	padding: 10px 20px;
	font-size: 14px;
  color: #fff;
  border: solid 1px $verde;
  transition: 0.2s;
	&.remove, &.excluir{
      background: $vermelho;
      border: solid 1px $vermelho;
      &:hover{
        color: $vermelho;
        background: transparent;
        border: solid 1px $vermelho;
      }
  }

	&.cancel{
      background: #8B8D97;
      border: solid 1px #8B8D97;
      &:hover{
        color: #8B8D97;
        background: transparent;
        border: solid 1px #8B8D97;
      }
  }
  &:hover{
    color: $verde;
    background: transparent;
    border: solid 1px $verde;
  }
  &.loading{
    display: flex;
    align-items: center;
    float: left;
    padding: 0 20px;
    .loadingbtn{
      display: block;
    }
    &:hover{
      color: #fff;
      background: $verde;
      border: solid 1px $verde;
    }

  }
}
h1{
	font-size: 20px;
	color: #292D41;
	font-weight: 400;
	align-items: center;
	margin: 20px;
	img{
			margin: 20px;
	}
}
.pagination{
  display: flex;
  margin: 20px 0 40px;
  .arrow{
    padding: 0 15px;
    background: #fff;
    height: 45px;
    border: solid 1px #ddd;
    transition: .2s;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-right: none;
    cursor: pointer;
    &.prev{
      border-radius: 6px 0 0 6px;
      border-right: none;
    }
    &.next{
      border-radius: 0 6px 6px 0;
      border-right: solid 1px #ddd;
    }
    &:hover, &.active{
      background: #1abc9c;
      color: #fff;
    }
    &:disabled{
      background: #e6e6e6;
      color: #999;
      cursor: auto;
      opacity: 0.5;
      &:hover{
        background: #e6e6e6;
        color: #999;
      }
    }
  }
}
